<template>
  <div class="company">
    <h1
      style="color: white; font-family: 'Raleway light', sans-serif"
      class="mt-3"
    >
      {{ $t("company_page.title") }}
    </h1>
    <p class="mt-5">
      {{ $t("company_page.descripiton1") }}
    </p>
    <p class="mt-5">
      {{ $t("company_page.descripiton2") }}
    </p>
    <v-row class="ma-0 justify-center pt-10 pb-10">
      <v-btn
        class="button start-btn font-weight-bold mr-5 px-8"
        height="72"
        style="font-family: 'Raleway light', sans-serif;"
        @click="registerModal = true"
      >
        {{ $t("start_trial_text") }}
      </v-btn>
    </v-row>
    <Login
      :dialog="loginModal"
      @closeModal="loginModal = false"
      @openSignUp="registerModal = true"
    />
    <Registration
      :dialog="registerModal"
      @closeModal="registerModal = false"
      @openSignIn="loginModal = true"
    />
  </div>
</template>

<script>
export default {
  name: "Company",
  components: {
    Registration: () => import("@/components/MainLanding/Modals/Registration"),
    Login: () => import("@/components/MainLanding/Modals/Login"),
  },
  data() {
    return {
      registerModal: false,
      loginModal: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.company {
  text-align: center;
  color: #ffffff;
  @media (max-width: 960px) {
    padding-top: 50px;
  }

  p {
    font-size: 20px;
    @media (max-width: 960px) {
      font-size: 25px;
    }
    @media (max-width: 600px) {
      font-size: 20px;
    }
  }

  .start-btn,
  .video-btn {
    @media (max-width: 960px) {
      height: 60px !important;
      width: 100% !important;
      margin: 10px 10px 0 10px !important;
    }
  }

  .video-btn {
    color: #ffffff;
    font-weight: bold;
  }
}
</style>
